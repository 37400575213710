<template>
  <div class="page-product-marketing">
    <section class="py-5 bg11 mobile-top">
      <div class="container">
        <input
          class="top-search bs-large"
          type="text"
          placeholder="Search Articles"
          v-model="search"
          @click="searchReset()"
        />
      </div>
    </section>

    <div class="container">
      <div class="row mt20">
        <div
          v-if="loading == true && !posts.length"
          class="col-xs-12 col-sm-12 col-12 col-lg-12"
          style="margin-bottom: 200px"
        >
          <div class="flex-box-wrap">
            <div class="flex-internal">
              <atom-spinner :animation-duration="1000" :size="40" :color="'#575757'" />
              <p>Loading......</p>
            </div>
          </div>
        </div>

        <div
          v-else-if="loading == false && !filteredPosts.length"
          class="col-xs-12 col-sm-12 col-12 col-lg-12 flexVHcenter"
        >
          <div>
            <img src="@/assets/img/sad.svg" />
            <p>Sorry, nothing was found...</p>
          </div>
        </div>

        <div v-else class="col-xs-12 col-sm-12 col-12 col-lg-12">
          <!-- pagination -->
          <div class="question-header d-flex align-items-center p-3 mt1r mb20 rounded bs-shadow">
            <!--
            {{"Filtered Page length: " + filteredQuestions.length / 25}}
            -->

            <div class="d-flex number-showing">
              <p class="mb0">Page {{currPage}} of {{ Math.ceil(this.filteredPosts.length / 25) }}</p>
            </div>

            <div class="pag-wrapper mlauto">
              <div class="nav-btns">
                <button v-if="this.currPage === 1" disabled>
                  <i class="material-icons">keyboard_arrow_left</i> Back
                </button>

                <button v-else @click.prevent="setPage(currPage-1)">
                  <i class="material-icons">keyboard_arrow_left</i> Back
                </button>

                <button
                  v-if="this.currPage === this.totalPage || this.currPage == Math.ceil(this.filteredPosts.length / 25)"
                  disabled
                >
                  Next
                  <i class="material-icons">keyboard_arrow_right</i>
                </button>

                <button v-else @click.prevent="setPage(currPage+1)">
                  Next
                  <i class="material-icons">keyboard_arrow_right</i>
                </button>
              </div>
            </div>
          </div>
          <!-- end pagination -->

          <div class="row row-eq-height">
            <div
              v-bind:key="post.id"
              v-for="(post, index) in filteredPosts.slice(pageStart, pageStart + countOfPage)"
              class="col-xs-12 col-sm-12 col-12 col-lg-4 mb20"
            >
              <div class="post">
                <!--
                <div class="post-icon"><i class="far fa-newspaper"></i></div>
                -->
                <h6 class="post-title" v-html="truncateTitle(post.title.rendered)"></h6>
                <!-- card -->

                <p v-html="truncateExcerpt(post.excerpt.rendered)" class="post-excerpt"></p>

                <div class="btn-wrap">
                  <router-link
                    :to="{ name: 'challengerinsightsPost', params: { slug: post.slug }}"
                    class="list-btn"
                  >Read More</router-link>
                </div>
              </div>
              <!-- post -->
            </div>
          </div>

          <!-- pagination -->
          <div class="question-header d-flex align-items-center p-3 mb20 rounded bs-shadow">
            <!--
            {{"Filtered Page length: " + filteredQuestions.length / 25}}
            -->

            <div class="d-flex number-showing">
              <p class="mb0">Page {{currPage}} of {{ Math.ceil(this.filteredPosts.length / 25) }}</p>
            </div>

            <div class="pag-wrapper mlauto">
              <div class="nav-btns">
                <button v-if="this.currPage === 1" disabled>
                  <i class="material-icons">keyboard_arrow_left</i> Back
                </button>

                <button v-else @click.prevent="setPage(currPage-1)">
                  <i class="material-icons">keyboard_arrow_left</i> Back
                </button>

                <button
                  v-if="this.currPage === this.totalPage || this.currPage == Math.ceil(this.filteredPosts.length / 25)"
                  disabled
                >
                  Next
                  <i class="material-icons">keyboard_arrow_right</i>
                </button>

                <button v-else @click.prevent="setPage(currPage+1)">
                  Next
                  <i class="material-icons">keyboard_arrow_right</i>
                </button>
              </div>
            </div>
          </div>
          <!-- end pagination -->
        </div>
      </div>
      <!-- end row -->
    </div>
  </div>
  <!-- product-marketing -->
</template>

<script>
import axios from "axios";
import { AtomSpinner } from "epic-spinners";
import Avatar from "vue-avatar";

//http://mktginfo.spacesaver.com/wp-json/wp/v2/posts
//http://mktginfo.spacesaver.com/wp-json/wp/v2/posts?slug=post-slug

const auth = {
  headers: {
    Authorization: "Bearer spacesaver/ab3f641f910eb693804bc5b097a5bda6"
  }
};

export default {
  name: "ChallerngerInsights",
  components: {
    AtomSpinner,
    Avatar
  },
  data() {
    return {
      challengerinsights: process.env.VUE_APP_CHALLENGER_INSIGHTS,
      search: "",
      posts: [],
      loading: true,
      countOfPage: 18,
      currPage: 1,
      noPostFound: false
    };
  },
  methods: {
    firstLoad: function() {
      this.loading = true;
      this.$store.commit("setActivePage", "initiatives");
      //this.posts = this.$store.state.challengerInsightsPosts;
      //this.loading = false;
      //return axios.get(this.endPointCompute, auth).then((response) => {
      if (this.$store.state.challengerInsights.challengerInsightsPosts.length) {
        this.posts = this.$store.state.challengerInsights.challengerInsightsPosts;
        this.loading = false;
      } else {
        return axios
          .get(`${this.challengerinsights}/wp-json/wp/v2/posts/?per_page=100`)
          .then(response => {
            console.log(response);
            this.posts = response.data;
            this.loading = false;
            this.$store.commit("SET_CHALLENGER");
          });
      }
    },

    truncateTitle: function(value) {
      if (value.length > 35) {
        value = value.substring(0, 55) + "...";
      }
      return value;
    },
    truncateExcerpt: function(value) {
      if (value.length > 85) {
        value = value.substring(0, 85) + "...";
      }
      return value;
    },
    searchReset: function() {
      // this.selectedCat = 0;
    },

    setPage: function(idx) {
      this.search = "";
      if (idx <= 0 || idx > this.totalPage) {
        return;
      }
      this.currPage = idx;
    }
  },
  computed: {
    activeNav() {
      return this.$store.state.activePage;
    },
    pageStart: function() {
      return (this.currPage - 1) * this.countOfPage;
    },
    totalPage: function() {
      return Math.ceil(this.posts.length / this.countOfPage);
    },
    filteredPosts: function() {
      //set the current page to 0
      this.currPage = 1;
      //if the search box is filled in
      if (this.search.trim() !== "") {
        return this.posts.filter(e => {
          //filter the subject line by what is filled in the search box
          return (
            e.title.rendered.toLowerCase().indexOf(this.search.toLowerCase()) >
            -1
          );
        });
      } else {
        //if the search box is not filled in
        return this.posts;
      }
    },

    foundNoPost: function() {
      if (this.search.length > 1 && this.filteredPosts.length === 0) {
        //this.noUserFound = true;
        return true;
      }
    },
    thePosts() {
      return this.$store.state.challengerInsightsPosts;
    }
  },

  created() {
    this.$store.commit("showNavigation");

    // console.log(this.$route.path);
  },
  mounted() {
    this.firstLoad();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.post {
  background: #fff;
  padding: 20px;
  margin-bottom: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.post a {
}
.post-excerpt {
  font-size: 14px;
}
.post button {
  position: absolute;
  bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: #00b4b4;
  color: #fff;
  border-radius: 4px;
}

.post .list-btn {
  display: inline-block;
}

.flex-box-wrap {
  margin-top: 100px;
}
.post-title {
  font-size: 1.2em;
}
.post-excerpt {
  padding-bottom: 0;
  margin-bottom: 0;
}
.post-top {
  display: flex;
}
.post-top h6 {
  margin-left: 10px;
}
.post-icon {
  color: rgba(44, 41, 61, 0.2);
  font-size: 1.3em;
}
.btn-wrap {
  margin-top: auto;
}
.question-header {
  background: #fff;
  color: #575757;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}
</style>
